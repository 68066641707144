import React from 'react';

const InfiniteLandscape = ({ ...props }) => {
  const { flow, imgUrl } = props;
  console.log({
    imgUrl
  })
  return (
    <div className={`InfiniteLandscapeDiv ${flow === 1 ? 'normal' : 'reverse'}`} style={{backgroundImage: `url(${imgUrl})`}}/>
  )
}
export default InfiniteLandscape;
