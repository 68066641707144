'use client'
import React from 'react';
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';

const Parallax = ({ ...props }) => {
  const { wrapperClass, children } = props;
  return (
    <ParallaxProvider>
      <ParallaxBanner
        className={`pictureGlass ${wrapperClass}`}
        layers={[
          {
            amount: 0.2,
            expanded: true,
          },
        ]}
      >
        {children}
      </ParallaxBanner>
    </ParallaxProvider>
  )
}

export default Parallax;
