/* lines */
const restaurantValance = '/resources/valances/cenefa-restaurante.svg';
const underlineTitle = '/resources/valances/linea-bajo-titulo.svg';
const dialogLine = '/resources/valances/linea-bocadillo.svg';
const categoryLine = '/resources/valances/linea-menu-categorias.svg';
const whiteUnderlineTitle = '/resources/valances/linea-bajo-titulo-blanca.svg';
const tieSeparator = '/resources/valances/separador.svg';

export const valanceImages = {
  restaurantValance,
  underlineTitle,
  dialogLine,
  categoryLine,
  whiteUnderlineTitle,
  tieSeparator,
};
