const btnLoginFocus = '/resources/buttons/rounded/bot-login-focus.svg';
const btnLoginOn = '/resources/buttons/rounded/bot-login-on.svg';
const btnLogin = '/resources/buttons/rounded/bot-login.svg';
const btnMenu = '/resources/buttons/rounded/bot-menu-off.svg';
const btnMenuOn = '/resources/buttons/rounded/bot-menu-on.svg';
const btnX = '/resources/buttons/rounded/bot-cerrar-x.svg';
const btnXR = '/resources/buttons/rounded/bot-cerrar-xr.svg';

export const roundedButtons = {
  /* LOGIN */
  btnLoginFocus,
  btnLoginOn,
  btnLogin,
  /* MENU */
  btnMenu,
  btnMenuOn,
  /* CLOSE */
  btnX,
  btnXR,
}

export default roundedButtons