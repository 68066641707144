"use client"
import React from "react";
import PropTypes from 'prop-types';
import './button.css';

export const BUTTON_STYLE = {
  SOLID: 'solid',
  REVERSE: 'reverse',
  UNSTYLED: 'unstyled',
};

export const BUTTON_THEME = {
  REVERSE: 'reverse',
  DEFAULT: 'default',
  UNSTYLED: 'unstyled',
};

const Button = ({
  className,
  onClick,
  children,
  style,
  theme,
  label,
}) => {

  const onClickHandler = () => {
    if (onClick) {
      onClick();
    }
  }

  return (
    <>
      <button className={`btn ${className} btn-${style} btn-theme-${theme}`} onClick={onClickHandler}>
        {label && (
          <span className="mimBold" dangerouslySetInnerHTML={{__html: label }}></span>
        )}
        {children}
      </button>
    </>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.string,
  theme: PropTypes.string,
  label: PropTypes.string,
}

Button.defaultProps = {
  className: '',
  style: BUTTON_STYLE.SOLID,
  theme: BUTTON_THEME.DEFAULT,
  label: '',
}

export default Button;