const sms1 = '/resources/web/slider/sms1.svg';
const sms1En = '/resources/web/slider/sms1En.svg';
const sms2 = '/resources/web/slider/sms2.svg';
const sms2En = '/resources/web/slider/sms2En.svg';
const sms3 = '/resources/web/slider/sms3.svg';
const sms3En = '/resources/web/slider/sms3En.svg';
const sms4 = '/resources/web/slider/sms4.svg';
const sms4En = '/resources/web/slider/sms4En.svg';
const sms5 = '/resources/web/slider/sms5.svg';
const sms5En = '/resources/web/slider/sms5En.svg';
const sms6 = '/resources/web/slider/sms6.svg';
const sms6En = '/resources/web/slider/sms6En.svg';

export const smsSlider = {
  sms1,
  sms1En,
  sms2,
  sms2En,
  sms3,
  sms3En,
  sms4,
  sms4En,
  sms5,
  sms5En,
  sms6,
  sms6En,
}
