'use client'
import React from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'next/navigation'
import PropTypes from 'prop-types';
import { getTextFromTextList } from '@/utils/handlers/immutableUtils';
import { textReferences } from '@/constants/textsReferences';
import { facebookIcon, twitterIcon, instagramIcon } from '@/resources/web/social'
import { logoSvgs } from '@/resources/logos';
import { svgs } from '@/resources/web/svg';
import { getCurrentYear } from '@/utils/common/get-current-year';
import './footer-web.css';
import Link from 'next/link';

const Footer = ({ ...props }) => {
  const router = useRouter();
  
  const { appTexts, currentLanguage, setCurrentLanguage } = props;
  const { logoConTituloGrisES, logoConTituloGrisEN } = logoSvgs;

  if (!appTexts) {
    return null;
  }

  const handleLanguage = () => {
    router.push(`/${currentLanguage === 'es' ? 'en' : 'es'}`)
  }

  const privacyPolicyText = getTextFromTextList(appTexts, textReferences.PRIVACY_POLICY_TEXT, currentLanguage);

  return (
    <div className="FooterHome">
      <div className="container">
        <div className="logoFotCont">
          <img className="logoFooter" src={currentLanguage === 'es' ? logoConTituloGrisES : logoConTituloGrisEN} alt="footer-logo" />
        </div>
        <div className="mimRights mimItalic">
          <div className="mimRights_text">
            <p>© {getCurrentYear()} MiM</p>
            -
            <Link href={`/${currentLanguage}/legals`} style={{textDecoration: 'unset'}}>
              <span>
                {privacyPolicyText}
              </span>
            </Link>
          </div>
          <div className="blackLine" />
        </div>
        <div className="socialSection">
          <div className="socialContainers">
            <a href="mailto:hello@mim.cool">hello@mim.cool</a>
            <img src={facebookIcon} alt="facebook" />
            <img src={twitterIcon} alt="twitter" />
            <img src={instagramIcon} alt="instagram" />
          </div>
        </div>
      </div>
      <div className="container dark">
        <div className="subContainer">
          <div className="language mimRegular">
            <span onClick={handleLanguage} role="button" tabIndex="-1">{currentLanguage === 'es' ? 'ENGLISH' : 'ESPAÑOL'}</span>
          </div>
          <div className="design mimRegular">
            <span className="label">design by</span>
            <img className="vic" src={svgs.vicLogo} alt="vic-logo" />
          </div>
        </div>
      </div>
    </div>
  )
}

Footer.propTypes = {
  appTexts: PropTypes.array.isRequired,
  currentLanguage: PropTypes.string,
  setCurrentLanguage: PropTypes.func.isRequired,
}

Footer.defaultProps = {
  appTexts: null,
  currentLanguage: 'es',
}

const mapStateToProps = state => ({
  /* appTexts */
  // appTexts: state.appTexts.texts,
  // currentLanguage: state.appTexts.currentLanguage,
});

const mapDispatchToProps = dispatch => ({
  // getMyTicket: () => dispatch(ticketActions.getMyTicket()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer);
