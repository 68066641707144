'use client'
import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { getTextFromTextList } from '@/utils/handlers/immutableUtils';
import { textReferences } from '@/constants/textsReferences';
import { svgs } from '@/resources/web/svg';
import PetImage from '@/components/pet-image';
import { petTypes } from '@/components/pet-image/pets';
import { imgs } from '@/resources/web/img';
import './i-want-it-section.css'

const IWantItSection = ({ ...props }) => {
  const { appTexts, currentLanguage } = props;
  const description1 = getTextFromTextList(appTexts, textReferences.HOME_I_WANT_IT_1, currentLanguage);
  const description2 = getTextFromTextList(appTexts, textReferences.HOME_I_WANT_IT_2, currentLanguage);

  return (
    <>
      <Image
        className="iwantImg"
        src={imgs.loQuiero}
        alt="i want it"
        layout="fill"
      />
      <div className="IWantItSection textContainer">
        <div className="readBlock">
          <div className="iwantIcon"><img src={svgs.iwantitSVG} alt="devices" /></div>
          <p dangerouslySetInnerHTML={{__html: description1 }} />
          <p dangerouslySetInnerHTML={{__html: description2 }} />
          <div className="devicesIwant"><img src={svgs.devicesSVG} alt="devices" /></div>
          <div className="iwkmoreContainer"><img src={svgs.iwkmoreSVG} alt="iwkmore" /></div>
        </div>
        {/* <div className="petSectionWI" /> */}
      </div>
      <div className="petSectionAbsoluteWI">
        <PetImage type={petTypes.OS2} />
      </div>
    </>
  )
}

IWantItSection.propTypes = {
  appTexts: PropTypes.array.isRequired,
  currentLanguage: PropTypes.string,
}

IWantItSection.defaultProps = {
  currentLanguage: 'es',
}

export default IWantItSection;
