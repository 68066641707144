"use client";
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getactiveMenu, resetactiveMenu, setactiveMenu } from '@/redux/ui';

import { actions as modalActions } from '@/redux/slices/modalSlice';
import { CONTENT_POSITION, IN_SCENE_EFFECT, OPACITY_LAYOUT_COLORS, OUT_SCENE_EFFECT } from '../modal';
import ImageButton from '@/components/image-button';
import { roundedButtons } from '@/resources/buttons/rounded';
import HomeMenu from '../float-menu/store/home-menu';
import LoginMenu from '../float-menu/store/login-menu';

import './float-buttons.css';

const MENU_COMPONENTS = {
  'MenuHome': HomeMenu,
  'MenuLogin': LoginMenu,
}

const FloatButtons = ({
  appTexts,
  currentLanguage,
}) => {
  const dispatch = useDispatch();

  const modalHistory = useSelector((state) => state.modals.modalHistory);

  let visibility = 'show';
  if (
    modalHistory
    && Array.isArray(modalHistory)
    && modalHistory.length > 0
    && modalHistory.some(({ id }) => id === 'legals')
  ) {
    visibility = 'hidden'
  }

  const [activeMenu, setActiveMenu] = useState(null);

  const onClickHandler = (activeMenuId) => {
    if (activeMenu !== activeMenuId) {
      setActiveMenu(activeMenuId);
    } else {
      setActiveMenu(null);
    }
    dispatch(
      modalActions.addModal({
        id: activeMenuId,
        Element2Show: MENU_COMPONENTS[activeMenuId],
        elementProps: {
          appTexts,
          currentLanguage
        },
        modalProps: {
          contentPosition: CONTENT_POSITION.CENTER_TOP,
          opacityLayoutColor: OPACITY_LAYOUT_COLORS.WHITE,
          inSceneEffect: IN_SCENE_EFFECT.FADE_IN_RIGHT,
          outSceneEffect: OUT_SCENE_EFFECT.FADE_OUT_RIGHT,
          closeByClickingOutside: false,
          scrollAllowed: true,
        },
      })
    );
  }

  return (
    <div className={`FloatButtons ${visibility}`}>
      <ImageButton
        wrapperClass={`FloatButton Menu${activeMenu === 'MenuHome' ? ' activated':''}`}
        image={activeMenu === 'MenuHome' ? roundedButtons.btnMenuOn : roundedButtons.btnMenu}
        // imageHover={roundedButtons.btnMenuOn}
        imageAlt="btnMenu"
        height={62}
        onClick={() => onClickHandler('MenuHome')}
      />
      <ImageButton
        wrapperClass={`FloatButton Login${activeMenu === 'MenuLogin' ? ' activated':''}`}
        image={activeMenu === 'MenuLogin' ? roundedButtons.btnLoginOn : roundedButtons.btnLogin}
        // imageHover={roundedButtons.btnLoginOn}
        imageAlt="btnLogin"
        height={62}
        onClick={() => onClickHandler('MenuLogin')}
      />
    </div>
  )
}

export default FloatButtons;
