'use client'
import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
// import ImageButton from "@/components/image-button";
import { getTextFromTextList } from '@/utils/handlers/immutableUtils';
import { textReferences } from '@/constants/textsReferences';
import { logoSvgs } from '@/resources/logos';
import { svgs } from '@/resources/web/svg';
// import { homeButtons } from '@/resources/web/buttons/home';
import './best-experience.css';
import Button from '../button';

const BestExperience = ({ ...props }) => {
  const { appTexts, currentLanguage } = props;

  if(!appTexts){
    return null;
  }

  const title1 = getTextFromTextList(appTexts, textReferences.HOME_BEST_TITLE1, currentLanguage);
  const freeTryLabel = getTextFromTextList(appTexts, textReferences.BUTTON_FREE_TRY, currentLanguage);

  return (
    <div className="BestExperience container" >
      <div className="BestExperience_info">
        <div className="logoContainer"><img src={logoSvgs.logoMimBlanco} alt="logo-mim" /></div>
        <div className="textContainer">
          <h2 dangerouslySetInnerHTML={{__html: title1 }} />
        </div>
        <div className="BestExperience_image">
          <Image
            className="BestExperienceImg_image-img"
            src={svgs.dibujo1}
            alt="Best Experience"
            layout="fill"
          />
        </div>
      </div>
      
      {/* <ImageButton
        wrapperClass="tryFreeButton"
        image={homeButtons.buttonTry1}
        imageHover={homeButtons.buttonTry2}
        link="/connectHelper/chooseBusiness/"
        imageAlt="prueba gratis"
        height={62} // TODO
      /> */}
      <div className="BestExperience_action">
        <Button
          className="tryButton"
          link="/connectHelper/chooseBusiness/"
          label={freeTryLabel}
        />
      </div>
    </div>
  )
}

BestExperience.propTypes = {
  appTexts: PropTypes.array.isRequired,
  currentLanguage: PropTypes.string,
}

BestExperience.defaultProps = {
  currentLanguage: 'es',
}

export default BestExperience;
