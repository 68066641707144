"use client";
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { actions as modalActions } from '@/redux/slices/modalSlice';
import PetImage from '@/components/pet-image';
import { petTypes } from '@/components/pet-image/pets';
import Valance, { valanceTypes } from '@/components/valance';
import { getTextFromTextList } from '@/utils/handlers/immutableUtils';
import { textReferences } from '@/constants/textsReferences';
import { logoSvgs } from '@/resources/logos';
import { SECTION1_ID, SECTION2_ID, SECTION3_ID } from '@/constants/constants';

import './home-menu.scss';

const HomeMenu = ({ appTexts, currentLanguage }) => {
  const dispatch = useDispatch();

  const option1 = getTextFromTextList(appTexts, textReferences.HOME_OPTION_1, currentLanguage);
  const option2 = getTextFromTextList(appTexts, textReferences.HOME_OPTION_2, currentLanguage);
  const option3 = getTextFromTextList(appTexts, textReferences.HOME_OPTION_3, currentLanguage);


  const handleMenuLink = (id) => {
    dispatch(
      modalActions.addModal({
        id: '',
      })
    );
    setTimeout(() => {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 500)
  }

  return (
    <div className="HomeMenu">
      <div className="container">
        <div className="menuOptions">
          <div className="mimLogoGap"><p><img className="mimLogo" src={logoSvgs.logoConTituloGrisES} alt="mim-logo" /></p></div>
          <div
            className={`optionMenu option1 ${currentLanguage}`}
            role="button"
            tabIndex="-1"
            onClick={() => {handleMenuLink(SECTION1_ID)}}
            dangerouslySetInnerHTML={{__html: option1 }}
          >
          </div>
            <Valance wrapperClass="menuValance" type={valanceTypes.CATEGORY} />
          <div
            className="optionMenu option2"
            role="button"
            tabIndex="-1"
            onClick={() => {handleMenuLink(SECTION2_ID)}}
            dangerouslySetInnerHTML={{__html: option2 }}
          >
          </div>
            <Valance wrapperClass="menuValance" type={valanceTypes.CATEGORY} />
          <div
            className="optionMenu option3"
            role="button"
            tabIndex="-1"
            onClick={() => {handleMenuLink(SECTION3_ID)}}
            dangerouslySetInnerHTML={{__html: option3 }}
          >
          </div>
            <Valance wrapperClass="menuValance" type={valanceTypes.CATEGORY} />
        </div>
        <div className="petGap">
          <PetImage type={petTypes.NORMAL} />
        </div>
      </div>
    </div>
  )
}

HomeMenu.propTypes = {
  appTexts: PropTypes.array.isRequired,
  currentLanguage: PropTypes.string.isRequired,
}

export default HomeMenu;
