import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import './ShootingElement.css';

export const getNumberBetweenMinMax = (min, max) => (Math.random() * (max - min) + min)
export const getRandomFlow = () => {
  let aux = Math.trunc(getNumberBetweenMinMax(1, 3));
  if(aux >= 2){
    aux = -1;
  } else {
    aux = 1;
  }

  return aux;
}
export const createKeyFramesNumber = (max = 5) => {
  const numberFrames = getNumberBetweenMinMax(2, max);
  return numberFrames;
}

export const createKeyFramesForShootingElement = () => {
  const numberOfFrames = Math.trunc(createKeyFramesNumber());
  const frames = [];
  let stepX = getNumberBetweenMinMax(0, 300) * -1; // -400
  // FLOW = -1
  const recorridoTotal = (Math.abs(stepX) + 100); // 100
  const deltaX = recorridoTotal / (numberOfFrames - 1);

  for(let i = 0; i < numberOfFrames; i += 1){
    const flowAux = getRandomFlow();
    if(i > 0){
      stepX += deltaX;
    }
    const res = {
      x: `${stepX}%`,
      y: `${getNumberBetweenMinMax(5, 55)}%`,
      rotateX: `${getNumberBetweenMinMax(0, 10) * flowAux}deg`,
      opacity: `${getNumberBetweenMinMax(5, 10) / 10}`,
    }
    frames.push(res);
  }
  return frames;
}

export const generateKeyFrames = () => {
  const keyFrames = createKeyFramesForShootingElement();
  const numberFrames = keyFrames.length -1;
  let allFrames = '';
  let lastOpacityValue = 0;
  keyFrames.forEach((frame, index) => {// 5 --> 100;
    const { x, y, rotateX, opacity } = frame;
    const percentageFrame = (index) * 100 / numberFrames;
    if(index === numberFrames){
      lastOpacityValue = opacity;
    }
    const frameStr = `${percentageFrame}% {
      transform-origin: left;
      transform: translate(${x}, ${y}) rotate(${rotateX});
      opacity: ${opacity};
    }`;
    allFrames = `${allFrames}${frameStr}`
  })

  return {allFrames, lastOpacityValue};
}

class ShootingElement extends Component {
  constructor(props) {
    super(props);
    const { imgSrc, percentageWidth, layoutHeight, flow, loopTime = 60} = props;
    const { allFrames, lastOpacityValue} = generateKeyFrames();
    const keyFramesCloud = keyframes`${allFrames}`;
    const zIndex = lastOpacityValue > 0.8 ? 1 : 0;
    const ShootingElementDiv = styled.div`
      position:absolute;
      width: 100%;
      z-index:${zIndex};
      animation: ${keyFramesCloud} ${loopTime}s linear  0s infinite;
    `;
    const AnimatedImg = styled.img`
      position:absolute;
      width: ${percentageWidth}%;
      min-width: ${percentageWidth * 10}px;
      height: auto;`;

    this.state = {
      ShootingElementDiv,
      AnimatedImg,
      flow,
      imgSrc,
    }
  }

  render() {
    const { AnimatedImg, ShootingElementDiv, flow, imgSrc } = this.state;

    return (
      <ShootingElementDiv className={`sed ${flow === 1 ? 'reverse' : 'normal'}`}>
        <AnimatedImg src={imgSrc} alt="shooting-element" />
      </ShootingElementDiv>
    )

  }
}

export default ShootingElement;
