const Cloud1 = '/resources/clouds/1.svg';
const Cloud2 = '/resources/clouds/2.svg';
const Cloud3 = '/resources/clouds/3.svg';
const Cloud4 = '/resources/clouds/4.svg';
const Cloud5 = '/resources/clouds/5.svg';
const Cloud6 = '/resources/clouds/6.svg';
const Cloud7 = '/resources/clouds/7.svg';
const Cloud8 = '/resources/clouds/8.svg';
const Cloud9 = '/resources/clouds/9.svg';
const Cloud10 = '/resources/clouds/10.svg';
const Cloud11 = '/resources/clouds/11.svg';
const Cloud12 = '/resources/clouds/12.svg';
const Cloud13 = '/resources/clouds/13.svg';
const Cloud14 = '/resources/clouds/14.svg';

export const Clouds = {
  Cloud1,
  Cloud2,
  Cloud3,
  Cloud4,
  Cloud5,
  Cloud6,
  Cloud7,
  Cloud8,
  Cloud9,
  Cloud10,
  Cloud11,
  Cloud12,
  Cloud13,
  Cloud14,
}
