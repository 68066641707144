const devicesSVG = '/resources/web/svg/devices.svg';
const iwantitSVG = '/resources/web/svg/iwanti.svg';
const iwkmoreSVG = '/resources/web/svg/iwkmore.svg';
const askSymbol = '/resources/web/svg/asksymbol.svg';
const vicLogo = '/resources/web/svg/viclogo.svg';
const dibujo1 = '/resources/web/svg/dibujo1.svg';

export const svgs = {
    devicesSVG,
    iwantitSVG,
    iwkmoreSVG,
    askSymbol,
    vicLogo,
    dibujo1,
}

