"use client";
import React from 'react';
import PropTypes from 'prop-types';
import { getTextFromTextList } from '@/utils/handlers/immutableUtils';
import { textReferences } from '@/constants/textsReferences';
import Valance, { valanceTypes } from '@/components/valance';
import { logoSvgs } from '@/resources/logos';
import './mim-description.css';
import AnimationCarouselle from '../animation-carouselle';
// import ImageButton from '../image-button';
import Button, { BUTTON_THEME } from '../button';
// import { homeButtons } from '@/resources/web/buttons/home';

const MiMDescription = ({ id, appTexts, currentLanguage }) => {
  const slogan = getTextFromTextList(appTexts, textReferences.HOME_SLOGAN, currentLanguage);
  const description1 = getTextFromTextList(appTexts, textReferences.HOME_DESCRIPTION1, currentLanguage);
  const description2 = getTextFromTextList(appTexts, textReferences.HOME_DESCRIPTION2, currentLanguage);
  const description3 = getTextFromTextList(appTexts, textReferences.HOME_DESCRIPTION3, currentLanguage);
  const knowMoreLabel = getTextFromTextList(appTexts, textReferences.BUTTON_KNOW_MORE, currentLanguage);
  const freeTryLabel = getTextFromTextList(appTexts, textReferences.BUTTON_FREE_TRY, currentLanguage);

  return (
    <div id={id} className="MiMDescription">
      <p align="center"><img className="mimLogo" src={logoSvgs.logoConTituloGrisES} alt="mim-logo" /></p>
      <h6 className="mimItalic mainTitle" align="center" dangerouslySetInnerHTML={{__html: slogan }} />
      <Valance wrapperClass="mainTitleValance" type={valanceTypes.CATEGORY} />
      <div className="descriptionContainer">
        <div className="description">
          <p dangerouslySetInnerHTML={{__html: description1 }} />
          <div className="blackLine mov" />
        </div>
        <div className="description">
          <p dangerouslySetInnerHTML={{__html: description2 }} />
          <div className="blackLine mov" />
        </div>
        <div className="description">
          <p dangerouslySetInnerHTML={{__html: description3 }} />
          <div className="blackLine mov" />
        </div>
      </div>
      <div className="descriptionContainer pc">
        <div className="blackLine" />
        <div className="blackLine" />
        <div className="blackLine" />
      </div>
      <AnimationCarouselle>
        <>
          {/* <ImageButton
            wrapperClass="knowButton"
            image={homeButtons.buttonKnow1}
            imageHover={homeButtons.buttonKnow2}
            link="/connectHelper/chooseBusiness/"
            imageAlt="saber mas"
            height={62} // TODO
          /> */}
          <Button
            theme={BUTTON_THEME.REVERSE}
            className="knowButton"
            link="/connectHelper/chooseBusiness/"
            label={knowMoreLabel}
          />
          <Button
            className="tryButton"
            link="/connectHelper/chooseBusiness/"
            label={freeTryLabel}
          />
          {/* <ImageButton
            wrapperClass="tryFreeButton"
            image={homeButtons.buttonTry1}
            imageHover={homeButtons.buttonTry2}
            link="/connectHelper/chooseBusiness/"
            imageAlt="prueba gratis"
            height={62} // TODO
          /> */}
        </>
        </AnimationCarouselle>
    </div>
  )
}

MiMDescription.propTypes = {
  appTexts: PropTypes.array.isRequired,
  currentLanguage: PropTypes.string,
  id: PropTypes.string,
}

MiMDescription.defaultProps = {
  currentLanguage: 'es',
  id: '',
}

export default MiMDescription;
