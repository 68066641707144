import React from 'react'
import { textReferences } from '@/constants/textsReferences';
import { getTextFromTextList } from '@/utils/handlers/immutableUtils';
import Input from '@/components/input';
import './login-form.scss';
import Button, { BUTTON_THEME } from '../button';

const LoginForm = ({ appTexts, currentLanguage, onSubmit }) => {
  const emailHolder = getTextFromTextList(appTexts, textReferences.HOME_LOGIN_EMAIL, currentLanguage);
  const keyHolder = getTextFromTextList(appTexts, textReferences.HOME_LOGIN_KEY, currentLanguage);
  const connectHolder = getTextFromTextList(appTexts, textReferences.HOME_LOGIN_CONNECT, currentLanguage);

  const handleSubmit = () => {
    const res = {
      email: '',
      password: '',
    }
    const emailElement = document.getElementById('emailLogin');
    if(emailElement && emailElement.value) {
      res.email = emailElement.value;
    }
    const keyElement = document.getElementById('passwordLogin');
    if(keyElement && keyElement.value) {
      res.password = keyElement.value;
    }
    if(onSubmit){
      onSubmit(res);
    }
  }

  return (
    <div className="LoginForm">
      <Input
        uppercase
        id="emailLogin"
        type="text"
        fontClass="mimItalic"
        theme="Black"
        typeTheme="Transparent"
        label={emailHolder}
        textAlign="left"
      />
      <Input
        id="passwordLogin"
        type="password"
        fontClass="mimItalic"
        theme="Black"
        typeTheme="Transparent"
        label={keyHolder}
        textAlign="left"
      />
      <Button
        className="buttonLogin"
        theme={BUTTON_THEME.REVERSE}
        role="Button"
        tabIndex="-1"
        onClick={handleSubmit}
        label={connectHolder}
      />
    </div>
  )
}

export default LoginForm;
