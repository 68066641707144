const loQuiero = '/resources/web/img/fondo-lo-quiero.jpg';
const servilleta = '/resources/web/img/fondo-servilleta.jpg';
const mensaje1 = '/resources/web/img/mensaje1.jpg';
const mensaje2 = '/resources/web/img/mensaje2.jpg';
const mensaje3 = '/resources/web/img/mensaje3.jpg';
const mensaje4 = '/resources/web/img/mensaje4.jpg';
const mensaje5 = '/resources/web/img/mensaje5.jpg';
const mensaje6 = '/resources/web/img/mensaje6.jpg';
const mensaje7 = '/resources/web/img/mensaje7.jpg';

export const imgs = {
  loQuiero,
  servilleta,
  mensaje1,
  mensaje2,
  mensaje3,
  mensaje4,
  mensaje5,
  mensaje6,
  mensaje7,
}
