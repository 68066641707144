import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PetImage from '@/components/pet-image';
import { petTypes } from '@/components/pet-image/pets';
import LoginForm from 'components/login-form';
import { getTextFromTextList } from '@/utils/handlers/immutableUtils';
import { textReferences } from '@/constants/textsReferences';
import { indicators } from '@/resources/indicators';
import './login-menu.scss';

class LoginMenu extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }

  onSubmit() {
    window.location.href = '/admin';
  }

  render(){
    const { appTexts, currentLanguage, setFloatMenuId, logged } = this.props;

    const registry = getTextFromTextList(appTexts, textReferences.HOME_LOGIN_SIGN_IN, currentLanguage);
    const forgotpswd = getTextFromTextList(appTexts, textReferences.HOME_LOGIN_FORGOT_PASSWORD, currentLanguage);

    const signOut = getTextFromTextList(appTexts, textReferences.HOME_LOGIN_SIGN_OUT, currentLanguage);
    const titleGoPanel = getTextFromTextList(appTexts, textReferences.HOME_GO_PANEL_TITLE, currentLanguage);
    const descriptionGoPanel = getTextFromTextList(appTexts, textReferences.HOME_GO_PANEL_DESCRIPTION, currentLanguage);
    const buttonGoPanel = getTextFromTextList(appTexts, textReferences.HOME_GO_PANEL_BUTTON, currentLanguage);

    return (
      <div className="LoginMenu">
        <div className="container">
          {!logged && (
            <div className="loginSection">
              <LoginForm appTexts={appTexts} currentLanguage={currentLanguage} onSubmit={this.onSubmit} />
              <p className="opSentence" dangerouslySetInnerHTML={{__html: registry }} />
              <p className="opSentence" dangerouslySetInnerHTML={{__html: forgotpswd }} />
            </div>
          )}
          {logged && (
            <div className="loginSection">
              <div className="button" dangerouslySetInnerHTML={{__html: signOut }} />
              <p className="titleGoPanel" dangerouslySetInnerHTML={{__html: titleGoPanel }} />
              <p className="descriptionGoPanel" dangerouslySetInnerHTML={{__html: descriptionGoPanel }} />
              <div className="button goPanelButton" dangerouslySetInnerHTML={{__html: buttonGoPanel }} />
            </div>
          )}
          <div className="petGap">
            {!logged && (
              <PetImage type={petTypes.SLEEPING}>
                <div className="zContainer first"><img src={indicators.letterZ} alt="z" /></div>
                <div className="zContainer second"><img src={indicators.letterZ} alt="z" /></div>
                <div className="zContainer third"><img src={indicators.letterZ} alt="z" /></div>
              </PetImage>
            )}
            {logged && (
              <PetImage type={petTypes.NORMAL} />
            )}
          </div>
        </div>
      </div>
    )
  }
}

LoginMenu.propTypes = {
  appTexts: PropTypes.array.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  setFloatMenuId: PropTypes.func.isRequired,
  logged: PropTypes.bool,
}

export default LoginMenu;
