'use client';
import React from 'react';
import PetImage from '@/components/pet-image';
import { petTypes } from '@/components/pet-image/pets';
import InfiniteLandscape from '@/components/infinite-landscape';
import ShootingElement from '@/components/shooting-element';
import { Clouds } from '@/resources/clouds';
import { landscapes } from '@/resources/landscape';
import './styles.scss';

export const getNumberBetweenMinMax = (min, max) => (Math.random() * (max - min) + min)
export const getRandomWidth = () => Math.trunc(getNumberBetweenMinMax(8, 15));
export const getRandomCloud = () => {
  const index = Math.trunc(getNumberBetweenMinMax(1 , 14))
  switch(index){
    case 1: return Clouds.Cloud1;
    case 2: return Clouds.Cloud2;
    case 3: return Clouds.Cloud3;
    case 4: return Clouds.Cloud4;
    case 5: return Clouds.Cloud5;
    case 6: return Clouds.Cloud6;
    case 7: return Clouds.Cloud7;
    case 8: return Clouds.Cloud8;
    case 9: return Clouds.Cloud9;
    case 10: return Clouds.Cloud10;
    case 11: return Clouds.Cloud11;
    case 12: return Clouds.Cloud12;
    case 13: return Clouds.Cloud13;
    default: return Clouds.Cloud14;
  }
};

export const createCloudsArray = () => {
  const clouds = [];
  const cloudNumber = Math.trunc(getNumberBetweenMinMax(10, 16));
  for (let c = 0; c < cloudNumber; c += 1) {
    const cloudProps = {
      imgSrc: getRandomCloud(),
      loopTime: getNumberBetweenMinMax(20, 50),
      percentageWidth: getRandomWidth(),
    }
    clouds.push(cloudProps);
  }
  return clouds;
}

const AnimationCarouselle = ({...props}) => {
  const { children, flow } = props;
  const clouds = createCloudsArray();

  return (
    <div className="AnimationCarouselle">
      {clouds.map((cloud, index) => (
        <ShootingElement key={index.toString()} layoutHeight={450} flow={flow} {...cloud} />
      ))}
      <InfiniteLandscape
        flow={flow}
        imgUrl={landscapes.landscape1}
        picRatio={9.226837060702875}
        loopTime={30}
        layoutHeight={450}
      />
      <div className={`${flow === -1 ? 'flying-left' : 'flying-right'}`}>
        <PetImage type={petTypes.FLYING} />
      </div>
      {props.children && (
        <div className="middleOfCarouselleAnimation">
          {props.children}
        </div>
      )}
    </div>
  )

}

export default AnimationCarouselle;
