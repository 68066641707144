'use client'
import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { getTextFromTextList } from '@/utils/handlers/immutableUtils';
import { textReferences } from '@/constants/textsReferences';
import PetImage from '@/components/pet-image';
import { petTypes } from '@/components/pet-image/pets';
import { svgs } from '@/resources/web/svg';
import { valanceImages } from '@/resources/valances';
import { imgs } from '@/resources/web/img';
import './question-section.css';

const QuestionsSection = ({ ...props }) => {
  const { appTexts, currentLanguage } = props;
  if (!appTexts) {
    return null;
  }
  const description1 = getTextFromTextList(appTexts, textReferences.HOME_HELP_TITLE1, currentLanguage);
  return (
    <>
      <Image
        className="QuestionsImg"
        src={imgs.servilleta}
        alt="question"
        layout="fill"
      />
      <div className="QuestionsSection">
        <div className="iconAskContainer"><img src={svgs.askSymbol} alt="icon-help" /></div>
        <div className="dialogContainer">
          <p dangerouslySetInnerHTML={{__html: description1 }} />
          <div className="dialogLineContainer">
            <img className="dialogLine" src={valanceImages.dialogLine} alt="dialog-line" />
          </div>
          <PetImage type={petTypes.NORMAL} />
        </div>
      </div>
    </>
  )
}

QuestionsSection.propTypes = {
  appTexts: PropTypes.array.isRequired,
  currentLanguage: PropTypes.string,
}

QuestionsSection.defaultProps = {
  currentLanguage: 'es',
}

export default QuestionsSection
