"use client";
import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import PetImage from '@/components/pet-image';
import { petTypes } from '@/components/pet-image/pets';
import { imgs } from '@/resources/web/img'
import { smsSlider } from '@/resources/web/slider';
import { logoSvgs } from '@/resources/logos';
import './home-slider.css';
import Image from 'next/image';

const HomeSlider = ({ currentLanguage }) => {
  const slides = [
    {
      idPic: '1',
      info: logoSvgs.logoEntrada,
      pet: null,
      bgImg: {
        src: imgs.mensaje1,
        alt: 'MiM'
      }
    },
    {
      idPic: '2',
      info: currentLanguage === 'es' ? smsSlider.sms1 : smsSlider.sms1En,
      pet: petTypes.HELP,
      bgImg: {
        src: imgs.mensaje2,
        alt: 'MiM'
      }
    },
    {
      idPic: '3',
      info: currentLanguage === 'es' ? smsSlider.sms2 : smsSlider.sms2En,
      pet: petTypes.NORMAL,
      bgImg: {
        src: imgs.mensaje3,
        alt: 'MiM'
      }
    },
    {
      idPic: '4',
      info: currentLanguage === 'es' ? smsSlider.sms3 : smsSlider.sms3En,
      pet: petTypes.OK,
      bgImg: {
        src: imgs.mensaje4,
        alt: 'MiM'
      }
    },
    {
      idPic: '5',
      info: currentLanguage === 'es' ? smsSlider.sms4 : smsSlider.sms4En,
      pet: petTypes.OS,
      bgImg: {
        src: imgs.mensaje5,
        alt: 'MiM'
      }
    },
    {
      idPic: '6',
      info: currentLanguage === 'es' ? smsSlider.sms5 : smsSlider.sms5En,
      pet: petTypes.LOVE,
      bgImg: {
        src: imgs.mensaje6,
        alt: 'MiM'
      }
    },
    {
      idPic: '7',
      info: currentLanguage === 'es' ? smsSlider.sms6 : smsSlider.sms6En,
      pet: petTypes.BEER,
      bgImg: {
        src: imgs.mensaje7,
        alt: 'MiM'
      }
    },
  ];

  const settingsForSlide = {
    accessibility: true,
    className: 'homeSlider',
    arrows: false,
    centerMode: false,
    variableWidth:false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    pauseOnHover: false,
    pauseOnFocus: true,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    speed: 500,
    lazyLoad: false,
    infinite: true,
  };

  return (
    <>
    <Slider {...settingsForSlide}>
      {slides.map((slide) => (
        <div key={slide.idPic} className={`container slide slide${slide.idPic}`}>
          {slide.bgImg?.src && (
            <Image
              className="slide-bgImg"
              src={slide.bgImg.src}
              alt={slide.bgImg.alt}
              layout="fill"
            />
          )}
          <div className="info">
            {slide.info && (
              <img className="circleInfo" src ={slide.info} alt="infoSlide" />
            )}
            {slide.pet && (
              <PetImage type={slide.pet} />
            )}
          </div>
        </div>
      ))}
    </Slider>
    </>
  );
}

HomeSlider.propTypes = {
  currentLanguage: PropTypes.string,
}

HomeSlider.defaultProps = {
  currentLanguage: 'es',
}

export default HomeSlider;
